@import '../../styles/utils/mixins.scss';

.heroContainer {
    display: flex;
    gap: 1rem;
    padding: 2rem 5%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &__card {
        img {
            overflow: hidden;
            border-radius: 5px;
        }
    }

    &__content {
        color: var(--white);
        h1 {
            font-size: 2.5rem;
            line-height: 2.5rem;
        }
        
        p {
            max-width: 500px;
            font-size: .9rem;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;  
            overflow: hidden; 
            margin: 1.5rem 0;
        }

        div {
            display: flex;
            gap:1rem;
            span {
                position: relative;
                display: inline-block;
    
                & + span {
                    @include separator;
                }

                a {
                    color: var(--primary);
                }
            }
        }

        &_more {
            color: var(--primary);
            margin-bottom: .5rem;
        }
    }
}

.skeleton {
    display: grid;
    grid-template-columns: 233px 1fr;
    gap: 2rem;
    width: 100%;
}

@media (max-width: 660px) {
    .heroContainer {
        flex-direction: column;
        &__content {
        }
    }

    .skeleton {
        grid-template-columns: 1fr;
    }
}