.cardListContainer {
    position: relative;
    margin-bottom: 3rem;

    h2 {
        color: var(--primary);
        font-size: 1.7rem;
        margin-left: 5%;
    }

    
    &__wrapper {
        overflow-x: hidden;
        
        button {
            transition: .2s;
            opacity: 0;
            position: absolute;
            z-index: 2;
            height: 337px;
            cursor: pointer;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.453);
            border: 0;
            color: var(--white);
            padding: 0 .5rem;
        }
        
        .btnNext {
            right: 0;
        }

        &:hover button {
            opacity: 1;
        }

        .container {
            transition: .2s;
            transform: translateX(0);
            margin: 0 5%;
            display: flex;
            // gap: .5rem;
        }
    }
}

@media (max-width: 800px) {
    .cardListContainer {
        &__wrapper {
            overflow: auto;

            button {
                display: none;
            }
        }
    }
}