@mixin separator {
    &::before {
        content: '';
        position: absolute;
        left: -.5rem;
        display: block;
        height: 100%;
        width: 2px;
        background-color: var(--primary);
        filter: brightness(.8);
    }
}