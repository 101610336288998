@import '../../styles/utils/mixins.scss';

.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 5%;
    height: 90px;

    h1 {
        font-weight: 600;
        color: var(--primary)
    }

    &__search {
        display: flex;
        align-items: center;
        padding: 0 .5rem;
        border-bottom: 2px solid var(--primary);
        border-radius: 5px 5px 0 0 ;

        .icon {

            font-size: 0;
            background-color: transparent;
            border:0 ;
            color: var(--white);
            cursor: pointer;
        }

        input {
            box-sizing: border-box;
            height: 40px;
            max-width: 400px;
            background-color: transparent;
            color: var(--white);
            border: 0;
            border-radius: 5px 5px 0 0 ;
            font-size: 1rem;
            transition: .2s;

            &:focus {
                outline: 0;
            }

            &::placeholder {
                color: var(--white);
            }
        }

        &:focus-within {
            background-color: #1d1f2b;
        }
    }

    nav {
        display: flex;
        gap: 1rem;

        a {
            position: relative;
            color: #fff;
            & + a {
                @include separator;
            }
        }
    }
}

@media (max-width: 750px) {
    .headerContainer {

        &__search {

            input {
               width: 0;
            }
            .active {
                width: 100%;
            }

        }
    }
}