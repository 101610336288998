.skeletonText, .skeletonImage, .skeletonTitle, .skeletonSubtitle  {
    background: linear-gradient(90deg, #1e212c 0px, #262835 50%, #1e212c 100%);
    animation: skeleton 1.5s infinite linear;
    background-size: 200%;
    flex-shrink: 0;
    margin: 1rem;
}
.skeletonText {
    // max-width: 700px;
    width: 100%;
    height: 150px;
    border-radius: 5px;
    // margin: 0 ;
    // height: 100%;
}


.skeletonImage {
    max-width: 250px;
    width: 100%;
    flex-shrink: 0;
    height: 350px;
    max-height: 400px;
    border-radius: 5px;
}

.skeletonTitle {
    border-radius: 5px;
    max-width: 400px ;
    width: 100% ;
    height: 3rem;
}

.skeletonSubtitle {
    border-radius: 5px;
    max-width: 200px;
    width: 100%;
    height: 3rem;
}

@keyframes skeleton {
    from {
        background-position: 0px;
    } to {
        background-position: -200%;
    }
}
