.animeContainer {
    .hero {
        height: 40vh;
        padding: 0 5%;
        background-position: center;
        background-size: cover;
        display: flex;

    }
    
    .container {
        display: flex;
        align-items: flex-start;
        padding: 0 5%;

        .card {
            flex-shrink: 0;
            transform: translateY(-50%);
            border-radius: 5px;
            width: 200px;
            overflow: hidden;
    
            img {
                width: 100%;
                height: 100%;
            }
        }

        .content {
            padding: 1rem;
            h1 {
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;  
                overflow: hidden; 
                
                color: var(--white);
                font-size: 5rem;
                line-height: 5rem;
            }
        }

    }
    .info {
        max-width: 1366px;
        margin: 0 auto;
        padding: 0 5%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        transform: translateY(-15%);
        
        .content {
            p {
                letter-spacing: 1px;
            }

            > div {
                display: flex;
                flex-direction: column;
                gap: .5rem;
                margin-bottom: 2rem;

                > div {
                    display: flex;
                    align-items: center;
                    gap: 1rem;

                    h3 {
                        position: relative;

                        &::before {
                            content: '';
                            display: block;
                            position: absolute;

                            bottom: 0;
                            width: 100%;
                            height: 5px;
                            background-color: var(--primary);
                        }
                    }
                }
            }
        }

        .iframeYoutube {
            position: relative;
            max-width: 100%;
        }
    }
}

.heroSkeleton {
    padding: 0 5%;
    width: calc(100vw - 10%);
    overflow: hidden;
    &__hero {
        width: 100%;
        display: grid;
        grid-template-columns: 300px 1fr;
        align-items: flex-end;
    }

    &__content {
        margin-top: 2rem;
    }
}

@media (max-width: 900px) {
    .animeContainer {
        .container {
            .content {
                h1 {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 250px;
                    font-size: 3rem;
                    line-height: 3rem;
                    -webkit-line-clamp: 3;
                }
            }
        }

        .info {
            transform: translateY(-10%);
        }
    }
}