.footerContainer{
    display: grid;
    place-items: center;
    height: 2rem;
    padding: 1rem 0;

    p {
        color: var(--primary);
        font-weight: 500;
    }

    div {
        font-size: 2rem;
        a {
            color: var(--white);
        }
    }
}