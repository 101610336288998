* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
:root {
    --background: #171923;
    --primary: #E4A15D;
    --white: rgba(255, 255, 255, 0.83);

    scroll-behavior: smooth;
}

body {
    background-color: var(--background);
    color: var(--white);
    font-family: 'Poppins', sans-serif;
}


a{text-decoration: none;}

h1 {
    font-size: 2rem;
}
h2 {
    font-size: 1.5rem;
}

@media (max-width: 1080px) {
    html {
        font-size: 93.75%;
    }
}

@media (max-width: 720px) {
    html {
        font-size: 87.5%;
    }
}
